import React, { useRef } from "react"
import Slider from "react-slick"


export default function SliderTechStack() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]
    };
    return (
        <div className=" mb-5 container_dron_brands">
            <div className="container">
                 <h2 className="helios_ttl text-center">Marcas</h2>
               
            <p className="helios_txt text-center text-center">Somos DISTRIBUIDORES de más de 10 compañías con alta experiencia en aeronaves no <br /> tripuladas y software de integración.</p>

            <div className="customer_slider mt-76">

                <Slider {...settings}>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434708/helios/Drones/Logos%20marcas/parrot_2x_b2qard.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434708/helios/Drones/Logos%20marcas/sherp_2x_bdizon.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/elistar_2x_gqxxhh.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/hikvision_2x_ycnngm.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/foxtech_2x_vpmaww.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/chasing_2x_eq6n2d.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/micasense_2x_h0skbq.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/av_2x_om8whx.png" />
                    </div>
                    <div className="customer-logo">
                        <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642434707/helios/Drones/Logos%20marcas/dji_2x_pv84lt.png" />
                    </div>
                    
                </Slider>
            </div>
           </div>
        </div>
    )
}