import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import Pricing from './style'
import PricingCard from "./Components/Card"
export default function PricingSection({ ...rest }) {
  return (
    <Pricing backgroundColor="#f3f4f6" {...rest}>
      <Container>
        <Row className="justify-content-center">
          {/* Single Table */}
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="Parrot" modelo="Anafi USA" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642031709/helios/Drones/drone_anafi_USA_2x_qgurp5.png"
              info1="Distancia máxima de 6000 m "
              info2="Tiempo de Vuelo Máx. 32 min"
              info3="Alcance de Transmisión 4 Km"
              info4="Velocidad Máx. 55 km/h "
              info5="Resistencia al Viento 50 km/h "
              info6="Certificado IP53 "
              info7="Temperatura Operación -35°C a 46°C "
              info8="Temperatura Infrarrojo -40°C a +150°C"
            />
          </Col>
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="DJI" modelo="Matrice 300 RTK" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642514357/helios/Drones/matrice_300_RTK_2x_uca5gx.png"
              info1="Distancia máxima de 7000 m "
              info2="Tiempo de Vuelo Máx. 50 min "
              info3="Alcance de Transmisión 15 Km "
              info4="Velocidad Máx. 23 m/s "
              info5="Resistencia al Viento 12 m/s "
              info6="Certificado IP45 Temperatura "
              info7="Operación -20°C a 50°C"
              info8=""
            />
          </Col>
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="DJI" modelo="Zenmuse H20T" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642514357/helios/Drones/zenmuse_H20T_2x_z5ekge.png"
              info1="Cámara Angular 12 MP "
              info2="Zoom 200x 20MP "
              info3="Cámara Térmica 640×512 "
              info4="Estabilización de Imagen y EIS "
              info5="Telémetro Láser Máx. 1200m "
              info6="Certíficado IP44 "
              info7="Temperatura Operación -10°C a 50°C"
              info8=""
            />
          </Col>
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="Anafi USA" modelo="Elistair Ligh-T4" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642514357/helios/Drones/elistair_ligh_T4_2x_a5cx5e.png"
              info1="Distancia máxima de 6000 m "
              info2="Longitud de Cable 70 m"
              info3="Compatibilidad Matrice 300 RTK"
              info4="Energía de 1.2 kW"
              info5="Fuerza de Tensión 100 daN "
              info6="Certificado IP53 "
              info7="Temperatura Operación -10°C a 40°C"
              info8="Permite mayor autonomía hasta 24hrs de vuelo para Matrice 300 RTK"
            />
          </Col>
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="Quantix Recon" modelo="AeroVironmnet" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642514357/helios/Drones/quantix_recon_2x_lwfmzg.png"
              info1="1 AV + 1 GCS DE VUELO"
              info2="Cobertura Área 3.24 km2 | Lineal 20 km"
              info3="Altura Máx. de 2,286 m "
              info4="Tiempo de Vuelo Máx. 45 min "
              info5="Alcance de Transmisión 2-40 km"
              info6="Velocidad Máx. 32 km/h"
              info7="Sensor Cámara 18 MP"
              info8="Temperatura Operación -17°C a 49°C33"
            />
          </Col>
          <Col xs="12" className="col-xs-12 col-md-6 col-lg-4">
            <PricingCard marca="Vapor 55" modelo="AeroVironmnet" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642514357/helios/Drones/vapor_55_2x_x40tab.png"
              info1="Cámara Angular 12 MP"
              info2="Altura Máx. 3,657 m"
              info3="Tiempo de Vuelo Máx. 60 min"
              info4="Alcance de Transmisión 8-20 km"
              info5="Velocidad Máx. 36 km/h"
              info6="Carga Peso Máx. 4.5 kg"
              info7="Temperatura Operación -20°C a 50°"
              info8=""
            />
          </Col>
        </Row>
      </Container>
    </Pricing>
  )
}