import React from "react"

export default function BannerOne() {
  return (
    <div className="bannerOne_drones">
      <div>
        <h1>DRONES / ATV / ROV / UAV</h1>
        <p>Contamos con diversas soluciones de desarrollo propio que cubren las operaciones de las <br />diversas áreas de las empresas en gobierno y privadas.</p>
        </div>
    </div>
  )
  
}
