import React from 'react'

export default function AdmonRPA({...rest}) {
  return (
  <div className="container container_admon_rpa">
    <div className = "row">
    <div className = 'info col-lg-6 col-md-6 col-sm-6 col-xs-12'>
     <div>
     <p>Tecnología y Digital</p>
      <h5>Administración RPAS</h5>
      <ul>
          <li>Plataforma de administración de aeronaves</li>
          <li>Transmisión de vídeo en tiempo real</li>
          <li>Registro de vuelo de las aeronaves registradas</li>
          <li>Selección de misiones predeterminadas</li>
          <li>Enlazado a centro de monitoreo</li>
          <li>Información en tiempo real y en cualquier punto</li>
      </ul>
     </div>
    </div>
    <div className = 'col-lg-6 col-md-6 col-sm-6 col-xs-12'>
      <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1642010536/helios/Drones/admin_rpas_2x_qxihdg.png" />
    </div>
    
  </div >
  </div>
  )
}