import React from 'react'

export default function CentroControl({...rest}) {
  return (
  <div className=" container_centro_control">
      <div>
      <div class="container">
      <h5>Integración Centro de Control</h5>
      <p>Conjunto de sistemas de control de vuelo que proporciona las funciones principales <br/> de monitoreo, despacho, control, preaviso y gestión integrada de trafico de drones.</p>
    <div className = "row">
    
    <div className = 'col-sm-4 col-xs-12'>
      <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1642010824/helios/Drones/cc_1_2x_dvc2av.png" />
    </div>
    <div className = 'col-sm-4 col-xs-12'>
      <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1642010823/helios/Drones/cc_2_2x_x38hz9.png" />
    </div>
    <div className = 'col-sm-4 col-xs-12'>
      <img className="w-100"  src="https://res.cloudinary.com/dv10simqm/image/upload/v1642010823/helios/Drones/cc_2_2x_x38hz9.png" />
    </div>
    
  </div >
      </div>
      </div>
  </div>
  )
}