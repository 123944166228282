import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ServicesSection() {
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              subTitle="DRONES"
              title="¿Por qué utilizar drones?"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px", className: "c-gray" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {/* Single Services */}
          <Col xs="12" className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
            <ServicesCard className="service_card_dron" title="Patrullas / Personal" icon="far fa-thumbs-down" iconColor="#000" text="Mayor número de personas <br /> Mayor riesgo de accidentes <br /> Solo tomas desde nivel de piso <br /> Mayor tiempo de respuesta <br /> Difícil de seguir malhechores" />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
            <ServicesCard className="service_card_dron" title="Drones" icon="far fa-thumbs-up" iconColor="#000" text="1-2 personas para monitorear grandes extensiones <br /> Tomas aéreas Completa movilidad <br /> Inspección térmica & Acuática <br /> Fácil persecución de personas o vehículos" />
          </Col>
          {/*/ .Single Services */}
          {/* <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="Email Marketing" icon="fas fa-envelope"  iconColor="#ff8d52"  text="There are many variations and<br class='d-none d-xs-block d-lg-none d-xl-block'> passages of Lorem
        lpsum<br class='d-none d-xs-block  d-lg-none d-xl-block'> available for use" />
      </Col> */}
          {/*/ .Single Services */}
          {/*/ .Single Services */}
          {/* <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
      <ServicesCard title="API Reference" icon="fas fa-chart-pie"  iconColor="#be79df"  text="There are many variations and<br class='d-none d-xs-block d-lg-none d-xl-block'> passages of Lorem
        lpsum<br class='d-none d-xs-block  d-lg-none d-xl-block'> available for use" />
      </Col> */}
          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  )
}

