import React from "react"
import { PageWrapper } from "~components/Core"
import ServicesSection from "../sections/drones-atv-rov-uav/Services"
import BannerOne from "../sections/drones-atv-rov-uav/BannerOne"
import SliderTechStack from "../sections/drones-atv-rov-uav/SliderTechStack"
import AboutSection from "../sections/drones-atv-rov-uav/About"
// import PricingSection from "../sections/drones-atv-rov-uav/Pricing"
import AdmonRPA from "../sections/drones-atv-rov-uav/AdmonRPA"
import CentroControl from "../sections/drones-atv-rov-uav/CentroControl"
import DontDron from "../sections/drones-atv-rov-uav/DontDron"
import FooterOne from "~sections/marketing/FooterOne"
import VigilanciaSubmarina from "~sections/drones-atv-rov-uav/VigilanciaSubmarina"
import Accesorios from "~sections/drones-atv-rov-uav/Accesorios"
import PricingSection from "../sections/drones-atv-rov-uav/PricingOne"

const header = {
  headerClasses:
    "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function DronesAtvRovUav() {
  return (
    <PageWrapper headerConfig={header}>
      <BannerOne />
      <ServicesSection />
      <SliderTechStack />
      <AboutSection />
      {/* <PricingSection /> */}
      <PricingSection />
      <AdmonRPA />
      <CentroControl />
      <DontDron />
      <VigilanciaSubmarina />
      <Accesorios />
      <FooterOne />
    </PageWrapper>
  )
}
