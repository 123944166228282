import React from "react"

import { Col, Row, Container } from 'react-bootstrap'
// ../PricingOne/style
import Pricing from '../VigilanciaSubmarina/PricingOne/style'
import PricingCard from "../VigilanciaSubmarina/PricingOne/Components/Card"

export default function VigilanciaSubmarina({ ...rest }) {
  return (
    <div className="container_submarina w-100">
      <div className="container">
        <div className="row header__info_submarina">
          <div className="h-100 img_container col-xs-12 col-md-6">
            <img
              className="w-100"
              src="https://res.cloudinary.com/dv10simqm/image/upload/v1642011009/helios/Drones/vig_submarina_2x_zh0wlx.png"
            />
          </div>
          <div className="h-100 info_container col-xs-12 col-md-6">
            <div className="">
              <small>Navega bajo el agua</small>
              <h5>Vigilancia submarina</h5>
              <p>
                Llegamos hasta los rincones más profundos del mundo contigo con
                equipos de vigilancia submarina.
              </p>
            </div>
          </div>
        </div>
        <div className="row row_prices">
          <div className="col-1"></div>
          <div className="col-xs-12 col-md-5">
          <PricingCard marca="Chasing" modelo="Underwater Drone M2 Pro" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642031588/helios/Drones/Underwater_Drone_M2Pro_2x_be7huj.png"
              info1="Profundidad máxima 150 m "
              info2="Ángulo de Visión 152° "
              info3="Velocidad 4 Nudos (2 m/s) "
              info4="Tiempo de Operación Máx. 5 hrs "
              info5="Batería 300 o 700Wh 12MP a 4K30fps"
              info6="Enfoque de 1m"
              info7="Temperatura Operación -10°C a 45°C"
              info8=""
            />
          </div>

          <div className="col-xs-12 col-md-5">
          <PricingCard marca="Chasing" modelo="Consola de pilotaje M2 Pro" pricetag="100,000"
              img="https://res.cloudinary.com/dv10simqm/image/upload/v1642031588/helios/Drones/Consola_pilotaje_2x_pi6usv.png"
              info1="Sistema Operativo Android "
              info2="Soporte Altura de 4 Niveles"
              info3="Capacidad 8 Interfaces "
              info4="Tiempo de Operación Máx. 8 hrs"
              info5="Pantalla de 13” y 1000cd/m2 "
              info6="Fuente de Alimentación 21.6V "
              info7="Temperatura Operación -10°C a 45°C"
              info8=""
            />
          </div>
          <div className="col-1"></div>
        </div>
      </div>
    </div>
  )
}
