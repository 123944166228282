import React, { useRef } from "react"
import Slider from "react-slick"

export default function Accesorios() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,

        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  }
  return (
    <div className=" mb-5 container_accesorios">
      <div className="container">
        <h2 className="typography__Heading-fvecw2-0 style__Title-sc-372oxm-1 hipacl gZXJhI text-center mt-5">
          Accesorios
        </h2>
        <p className="text-center typography__Paragraph-fvecw2-1 style__Text-sc-372oxm-3 oKLNJ gvgGwQ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut <br /> lambore et dolore magna aliqua.
        </p>

        <div className="customer_slider mt-76">
          <Slider {...settings}>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031934/helios/Drones/Accesorios/ACC_GM2PRO_11_2x_msi2fs.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031934/helios/Drones/Accesorios/ACC_GM2PRO_09_2x_nbqlkp.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031934/helios/Drones/Accesorios/ACC_GM2PRO_10_2x_wbmfsv.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031934/helios/Drones/Accesorios/ACC_GM2PRO_08_2x_chzhgc.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_05_2x_wtpgcz.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_06_2x_aa8vlt.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_04_2x_gvulz0.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_03_2x_ygvfkz.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_07_2x_t1bbfw.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_02_2x_cii6hu.png"
              />
            </div>
            <div className="customer-logo">
              <img
                className="w-100"
                src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031933/helios/Drones/Accesorios/ACC_GM2PRO_01_2x_w01z2e.png"
              />
            </div>
          </Slider>
        </div>
        <hr />
        <div className="row row_last_img">
          <div className="col-xs-12 col-md-4">
            <img
              className="w-100"
              src="https://res.cloudinary.com/dv10simqm/image/upload/v1642011148/helios/Drones/dron_subm_3_2x_yabkvm.png"
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <img
              className="w-100"
              src="https://res.cloudinary.com/dv10simqm/image/upload/v1642011148/helios/Drones/dron_subm_2_2x_hpat8w.png"
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <img
              className="w-100"
              src="https://res.cloudinary.com/dv10simqm/image/upload/v1642011147/helios/Drones/dron_subm_1_2x_pjey5z.png"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
