import React from 'react'

export default function DontDron({...rest}) {
  return (
    <div className="w-100 dont-dron-container">
    
    <div className='container'>
        <div className="row">
        <div className="col-xs-12 col-md-6">
          <div>
            <small>Importante</small>
            <h5>¿Qué no hacer con <br/> el dron?</h5>

            <ul>
              <li>Volar en lugares cerrados o con obstáculos en su alrededor (árboles, edificios, torres de comunicación)</li>
              <li>Volar en clima adverso (niebla, lluvia, nieve y vientos ≤ 15m/s)</li>
              <li>Atender llamadas, mensajes o cualquier otra cosa mientras se pilotea.</li>
              <li>Volar cerca de electromagnetismo (Subestaciones eléctricas, Torres de Alta tensión, cableado eléctrico)</li>
              <li>Volar a más de 120m de altura.</li>
              <li>Acercarse, tocar o sujetar el dron con propelas encendidas</li>
              <li>Volar en Zonas GEO y Zonas prohibidas por la AFAC</li>
              <li>Dejar baterías bajo el sol</li>
              <li>Descargar el 85% o más de la batería en cada vuelo</li>
              <li>Volar con propelas dobladas, astillados, desgastadas o rotas.</li>
              <li>Volar y Almacenar el dron con restos de humedad</li>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
         <img className='w-100 img' src="https://res.cloudinary.com/dv10simqm/image/upload/v1642010878/helios/Drones/dron_2_2x_qkvilv.png" />
        </div>
        </div>
    </div>
    </div>
  )
}