import React from "react"
import Card from "./style"

export default function PricingCard({ marca, modelo, pricetag, img, info1, info2, info3, info4, info5, info6, info7, info8, ...rest }) {
  return (
    <Card backgroundColor="#fff" {...rest}>
      <Card.Top>
        <Card.Title as="h3">{marca}</Card.Title>
        <Card.Text>{modelo}</Card.Text>
        {/* <Card.Price>
          <Card.PriceText alignSelf="flex-start">$</Card.PriceText>
          <Card.Amount>{pricetag}</Card.Amount>
          <Card.PriceText>/ MXN</Card.PriceText>
        </Card.Price> */}
      </Card.Top>
      <Card.List>
        <Card.ListItem className="h-30">{info1} </Card.ListItem>
        <Card.ListItem className="h-30">{info2}</Card.ListItem>
        <Card.ListItem className="h-30">{info3}</Card.ListItem>
        <Card.ListItem className="h-30">{info4}</Card.ListItem>
        <Card.ListItem className="h-30">{info5}</Card.ListItem>
        <Card.ListItem className="h-30">{info6}</Card.ListItem>
        <Card.ListItem className="h-30">{info7}</Card.ListItem>
        <Card.ListItem className="h-30">{info8}</Card.ListItem>
      </Card.List>
      <Card.Box>
        <img className="w-100" src={img} />
        {/* <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642031709/helios/Drones/drone_anafi_USA_2x_qgurp5.png" /> */}
      </Card.Box>
    </Card>

  )
}
